
import { Component, Ref, Vue } from 'vue-property-decorator';
import useFormsHeader from '@/stores/forms/header';
import useFormsSubmissions from '@/stores/forms/submissions';
import useFormsStore from '@/stores/forms/forms';
import { formatFullRelativeDate } from '@/utils';
import { Submission, SubmissionType, SubmissionViewType } from '@/views/forms/submissions/types';
import SubmissionView from '@/views/forms/submissions/modals/submission-view.vue';
import ToolbarButton from '@/views/forms/components/toolbar-button.vue';

@Component({
  components: {
    SubmissionView,
    ToolbarButton,
  },
})
export default class SubmissionViewPage extends Vue {
  @Ref()
  private readonly SubmissionViewRef: SubmissionView;

  private submission: Submission | null = null;

  private formsStore = useFormsStore();

  private formsHeader = useFormsHeader();

  private formsSubmissions = useFormsSubmissions();

  private formatDate(date: string) {
    return formatFullRelativeDate(date);
  }

  private print() {
    this.SubmissionViewRef.print();
  }

  private async deleteSubmission() {
    if (!window.confirm('Are you sure you want to delete submission?')) {
      return;
    }

    const { formId, submissionId } = this.$route.params;

    if (this.submission) {
      await this.formsSubmissions.deleteSubmissions({ ids: [submissionId] });
      this.$router.push({
        name: 'Forms_Forms_Submissions',
        params: { formId, submissionId: '' },
      });
    }
  }

  async created() {
    const { formId, submissionId } = this.$route.params;
    const submissionType = (this.$route.query.type as SubmissionType) || SubmissionType.Submission;

    const formName = (await this.formsStore.loadForm({ formId })).data.name;
    this.formsHeader.pageTitle = formName;
    this.formsHeader.setPageBreadcrumb({ name: 'Forms_Forms_Submissions', title: formName });

    this.formsSubmissions.setType(submissionType);

    let submission = null;

    try {
      submission = await this.formsSubmissions.getSubmissionById({ id: submissionId });
    } catch {
      this.formsSubmissions.setType(
        submissionType === SubmissionType.Draft ? SubmissionType.Submission : SubmissionType.Draft,
      );
      try {
        submission = await this.formsSubmissions.getSubmissionById({ id: submissionId });
      } catch {
        return;
      }
    }

    this.formsHeader.setPageBreadcrumb({
      name: 'Forms_Forms_Submissions_View',
      title: this.formatDate(submission.created),
    });

    await this.formsSubmissions.loadData();

    this.submission = {
      ...submission,
      ...JSON.parse(submission.dataJson),
      __created: submission.created,
      __modified: submission.lastModified,
    } as Submission;

    await this.formsSubmissions.readSubmissions({ ids: [submissionId] });
  }

  get SubmissionViewType() {
    return SubmissionViewType;
  }

  beforeDestroy() {
    this.formsSubmissions.clear();
  }
}
